import api from './api';
// 获取店铺基本信息
export const $sg_getShopInfo = () => api.get('/mall/v1/ShopInfo/getShopInfo');
// 修改店铺
export const $sp_saveShopInfo = (params) => api.post('/mall/v1/Shop/saveShopInfo', params);
// 平台获取获取店铺附加信息
export const $sg_getShopExt = () => api.get('/mall/v1/ShopManager/getShopExt');
// 获取店铺类型
export const $sg_getShopCate = () => api.get('/mall/v1/Shop/getShopCate');
// 获取商家对商品的权限配置和营销配置
export const $sgw_getShopAuthorityV2 = () => api.getNoMessage('/platform/v2/SpreadConfig/getShopAuthorityV2');

// 获取店铺配送费计价方案
export const $sp_getDistributionConfig = (params) => api.getNoMessage('/mch/v1/Distribution/getDistributionConfig', params);

export const $sp_setDistributionConfig = (params) => api.post('/mch/v1/Distribution/setDistributionConfig', params);
// 删除店铺计费方案
export const $sp_delDistributionConfigShop = (params) => api.get('/platform/v1/SpreadDistributionConfigShop/delDistributionConfig', params);
// 获取外送配置
export const $sg_getMallShopConfig = (params) => api.get('/mall/v2/MallShop/getMallShopConfig', params);
