<template>
  <div class="index">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
  .index{
    height: 100%;
  }
</style>
