import api from './api';

// 待审核商户页面
export const $sg_notReviewedShopPage = (params) => api.post('/j/agent/t-shop-info/notReviewedShopPage', params);

// 已审核商户页面
export const $sg_reviewedShopPage = (params) => api.postNoMessage('/j/agent/t-shop-info/reviewedShopPage', params);

// 添加商户页面
export const $sg_join = (params) => api.post('/j/agent/t-shop-join/join', params);

// 添加商户页面
export const $sg_updateByJoinId = (params) => api.post('/j/agent/t-shop-join/updateByJoinId', params);

// 查询商户信息
export const $sg_queryByContactTel = (params) => api.get('/j/agent/t-shop-join/queryByContactTel', params);

// 请求美团UISDK连接商户登录页面
export const $sg_doAppAuthToken = (params) => api.get('/j/meituan/doAppAuthToken', params);

// 美团商户门店解绑链接
export const $sg_doShopUnBind = (params) => api.get('/j/meituan/doShopUnBind', params);

export const $sp_updateShopGoodsListenerpic = (params) => api.get('/api/agent/biz/merchant/saveShopGoodsLicensePic', params);

