<template>
  <div class="business-reviewed-page">
    <div class="searchForm">
      <el-form ref="form" :model="form" :inline="true" label-position="left" class="form">
        <el-form-item label="商户名称/电话">
          <el-input class="inputStyle" v-model="form.shopName" @input="inputShopId" placeholder="商户名称/电话"></el-input>
        </el-form-item>
        <el-form-item label="业绩归属人/手机号">
          <el-input type="text" class="inputStyle" v-model="form.belong" placeholder="业绩归属人/手机号"></el-input>
        </el-form-item>
        <!-- <el-form-item label="归属分公司">
          <el-select v-model="form.spreadId" placeholder="请选择分公司" clearable filterable remote :loading="loading"
            :remote-method="searchBranchOffice" @focus="searchBranchOffice('')">
            <el-option v-for="item in branchOfficeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="店铺区域">
          <area-select :addressFlag="addressFlag" v-model="area" class="iblock"></area-select>
        </el-form-item>
        <el-form-item label="开通业务">
          <el-select v-model="form.supportBusiness" placeholder="请选择开通业务" style="width:120px" clearable>
            <el-option v-for="item in openBusiness" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号状态">
          <el-select v-model="form.telState" placeholder="请选择账号状态" style="width:160px" clearable>
            <el-option v-for="item in accountList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二维码配置状态">
          <el-select v-model="form.billboardState" placeholder="二维码配置状态" style="width:150px" clearable>
            <el-option v-for="item in codeTagList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="年服务费">
          <el-select v-model="form.expireTimeState" placeholder="年服务费" style="width:140px" clearable>
            <el-option v-for="item in takeCoutList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="小程序认证费">
          <el-select v-model="form.miniExpireTimeState" placeholder="小程序认证费" style="width:140px" clearable>
            <el-option v-for="item in takeCoutList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" size="normal">
          <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
            <el-button type="primary" class="sure" @click="screen">筛选</el-button>
            <el-button type="info" class="sure" @click="clear">清空</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="shopId" label="商户ID" width="80"></el-table-column>
      <el-table-column prop="shopName" label="商户名称"></el-table-column>
      <el-table-column prop="tel" label="店铺账号" width="100"></el-table-column>
      <el-table-column prop="areaName" label="店铺区域" width="80"></el-table-column>
      <el-table-column prop="takeawayTotalOrderCount" label="外卖单量" width="80">
        <template slot-scope="scope">
           <span>{{scope.row.takeawayTotalOrderCount?scope.row.takeawayTotalOrderCount:0}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="doshokuTotalOrderCount" label="堂食单量" width="80">
        <template slot-scope="scope">
           <span>{{scope.row.doshokuTotalOrderCount?scope.row.doshokuTotalOrderCount:0}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="takeawayAmount" label="总收益" width="80">
         <template slot-scope="scope">
           <span>{{scope.row.takeawayAmount?scope.row.takeawayAmount:0}}</span>
          </template>
      </el-table-column>
       <el-table-column prop="waitAccountAmount" label="待结算" width="80">
         <template slot-scope="scope">
           <span>{{scope.row.waitAccountAmount?scope.row.waitAccountAmount:0}}</span>
          </template>
       </el-table-column>

      <el-table-column prop="balance" label="可提现" width="80">
         <template slot-scope="scope">
           <span>{{scope.row.balance?scope.row.balance:0}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="widthrow" label="已提现" width="80">
        <template slot-scope="scope">
           <span>{{scope.row.widthrow?scope.row.widthrow:0}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="supportBusiness" label="开通业务" width="60">
        <template slot-scope="scope">
          {{getSupportBussinessDesc(scope.row.supportBusiness)}}
        </template>
      </el-table-column>
       <el-table-column prop="expireTimeState" label="年服务费" width="60">
        <template slot-scope="scope">
          {{scope.row.expireTimeState==1?'已缴':'未缴'}}
        </template>
      </el-table-column>
        <el-table-column prop="supportBusiness" label="小程序认证费" width="60">
        <template slot-scope="scope">
          {{scope.row.miniExpireTimeState==1?'已缴':'未缴'}}
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="抽佣比例" width="60">
       <template slot-scope="scope">
           <span>{{scope.row.amount?scope.row.amount*1+'%':'0%'}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="microState" label="进件状态">
      <template slot-scope="scope">
         <span>{{scope.row.microState?microStateList[scope.row.microState]:'未进件'}}</span>
      </template>
      </el-table-column>
      <!-- <el-table-column prop="eatIn" label="食品安全许可证" width="80">
        <template slot-scope="{ row }">
          <div>
            <el-image
              style="width: 60px; height: 60px"
              :src="$imgPrefix(row.goodsLisencePic, '', 60)"
              :preview-src-list="[row.goodsLisencePic]">
              <div slot="error" class="shop-list-image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div class="center">
              <el-link @click="beginChangeShopFoodCard(row)" type="primary">上传</el-link>
            </div>
          </div>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="systemSubsidy" label="配送补贴" width="90">
        <template slot-scope="scope">
          <input class="vue-input-style" @change="changSubsidy($event,scope.row)" :value="scope.row.systemSubsidy" type="number" />
        </template>
      </el-table-column> -->
      <el-table-column prop="secondQrUrlStatus" label="引流二维码" width="60">
        <template slot-scope="scope">
          <span :class="{pointer:true,active:!!scope.row.secondQrUrlStatus}"
            @click="editOrAddCode(scope.row)">{{scope.row.secondQrUrlStatus?'修改':'配置'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="belongName" label="业绩归属人" width="80"></el-table-column>
      <el-table-column prop="belongPhone" label="归属人手机号" width="80"></el-table-column>
      <el-table-column prop="platName" label="归属分公司"></el-table-column>
      <el-table-column prop="telState" label="账号状态" width="80">
        <template slot-scope="scope">
          <span>{{stateTel[scope.row.telState]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="telState" label="朋友圈广告" width="80">
        <template slot-scope="scope">
          <span style="color: #f22424;cursor: pointer;" @click="gotoAd(scope.row.shopId)">添加</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="美团外卖订单授权" width="80" v-if="hadSetRider">
        <template slot-scope="scope">
          <span class="goAuthorize" @click="goAuthorize(scope.row)">{{+scope.row.authStatus === 2?'去授权':'解绑'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="操作" fixed="right" width="140" align="center">
        <template slot-scope="scope">
          <div>
            <!-- <span style="color: #f22424;cursor: pointer;" @click="editBuiness(scope.row)">编辑</span> -->
            <el-divider direction="vertical"></el-divider><span style="color: #f22424;cursor: pointer;"
              @click="editOpenState(scope.row)">{{scope.row.status==1?'停业':'恢复营业'}}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <Dialog ref="business_1"></Dialog>
    <el-dialog :visible.sync="centerDialogVisible" width="500px" class="dialong-style" center>
      <p>
        <span class="remark-line"></span>
        <span class="remark-title">引流二维码设置</span>
      </p>
      <header style="padding: 30px 0">
        <el-checkbox v-model="codeform.status">是否开启</el-checkbox>
      </header>
      <aside v-if="codeform.status">
        <p style="padding-bottom: 5px">二维码设置</p>
        <div class="iblock">
          <img class="pointer team_img" title="点击下方按钮添加图片" id="teamImage" width="150" height="150"
            :src="codeform.secondQrUrl ? codeform.secondQrUrl : defaultImg" />
          <el-upload class=" " action="#" :show-file-list="false" :http-request="img_head_upload">
            <p class="unploadButton">选择文件</p>
          </el-upload>
        </div>
        <p style="line-height: 40px">链接广告文字内容</p>
        <el-input v-model="codeform.linkBillboardContent" clearable> </el-input>
        <!-- <p style="line-height: 40px">链接url</p>
        <el-input :disabled="isread" v-model="codeform.linkUrl" clearable>
        </el-input> -->
      </aside>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveBillboard">保存</el-button>
        <el-button @click="centerDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/dialog.vue';
import ChooseFile from '@base/utils/file/ChooseFile';
import FileUploader from '@base/utils/file/FileUploader';
import {
  // $sg_reviewedShopPage,
  $sg_doAppAuthToken,
  $sg_doShopUnBind,
  $sp_updateShopGoodsListenerpic,
} from '@/api/business';
import {
  $agent_shopPage, $sp_spreadInfoList, $sg_saveBillboard, $sg_editSystemSubsidy, $sg_updateShopState,
} from '@/api/agentAll';
import { $sg_selectMeituanSetting } from '@/api/setting';
import AreaSelect from '@/components/common/AreaSelect.vue';
import { UploadImg } from '@/global/common';
import { defaultImg } from '@/assets/img/unploadHeadImg.png';

const codeTagList = [{ label: '未配置', value: 0 }, { label: '已配置', value: 1 }];
const accountList = [{ label: '正常', value: 1 }, { label: '冻结', value: 4 }];
const openBusiness = [{ label: '外卖', value: 1 }, { label: '堂食', value: 4 }, { label: '堂食+外卖', value: 5 }];
const stateTel = ['', '正常', '隐藏', '删除', '禁用'];
const takeCoutList = [{ value: 1, label: '已缴费' }, { value: 2, label: '未缴费' }];
const microStateList = ['', '审核中', '已驳回', '待签约', '已完成', '资料校验中', '账户待验证', '已冻结'];
export default {
  name: 'reviewed',
  components: {
    Dialog, AreaSelect,
  },
  data() {
    return {
      defaultImg,
      codeTagList,
      accountList,
      openBusiness,
      stateTel,
      takeCoutList,
      microStateList,
      area: [],
      addressFlag: true,
      loading: false,
      centerDialogVisible: false,
      form: {
        belong: '',
        billboardState: '',
        shopName: '',
        spreadId: '',
        supportBusiness: '',
        telState: '',
      },
      businessTagList: [],
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      branchOfficeList: [],
      addressList: [],
      codeform: {

        shopId: null,
        status: false,
        secondQrUrl: '',
        linkBillboardContent: '加粉丝福利群，更多福利，更多优惠',
      },
      hadSetRider: false, // 是否设置骑手配送
    };
  },
  created() {
    const area = JSON.parse(sessionStorage.getItem('formatArea'));
    this.areaTree(area);
    this.getList({
      pageCurrent: this.currentPage,
      pageSize: 15,
    });
    this.searchBranchOffice('');
    $sg_selectMeituanSetting().then((d) => {
      if (!d) return;
      this.hadSetRider = Number(d.val) === 1;
    });
  },
  mounted() {
    const area = JSON.parse(sessionStorage.getItem('formatArea'));
    this.areaTree(area[0].children, '230000');
  },
  methods: {
    beginChangeShopFoodCard(row) {
      const change = new ChooseFile();
      change.chooseImage(this.getFile.bind(this, row));
    },
    getFile(row, file) {
      console.log(file, '124');
      const fileUploder = new FileUploader(file, {
        compress: false,
        addLibrary: false,
      });
      fileUploder
        .upload()
        .then((url) => {
          this.updategoodsLisencePicUrl(row, url);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async updategoodsLisencePicUrl(row, url) {
      await $sp_updateShopGoodsListenerpic({
        goodsLicensePic: url,
        shopId: row.shopId,
      });
      this.$message.success('操作成功');
      // eslint-disable-next-line
      row.goodsLisencePic = url;
    },
    // 引流二维码
    editOrAddCode(item) {
      console.log(item, '333');
      this.codeform.shopId = item.shopId;
      this.codeform.status = !!item.secondQrUrlStatus;
      this.codeform.secondQrUrl = item.secondQrUrl;
      this.codeform.linkBillboardContent = item.linkBillboardContent;
      this.centerDialogVisible = true;
    },
    // 归属员工查询
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.reviewedShopPage(query);
      } else {
        await this.reviewedShopPage('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    reviewedShopPage(platName = '') {
      const params = {
        platName,
        pageSize: 200,
      };
      return $sp_spreadInfoList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });

        this.branchOfficeList = tempArr;
      });
    },
    // 修改配送费
    changSubsidy(event, item) {
      this.$confirm(`确认将奈雪的茶配送费补贴修改为元${event.target.value}/单吗`, '修改配送补贴', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $sg_editSystemSubsidy({ systemSubsidy: event.target.value, shopId: item.shopId }).then(() => {
          this.getList({
            ...this.form,
            pageCurrent: this.currentPage,
            pageSize: 15,
          });
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消',
        });
      });
    },
    editOpenState(item) {
      // eslint-disable-next-line eqeqeq
      const status = item.status == 1 ? 4 : 1;
      this.$confirm('确认修改营业状态', '修改营业状态', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $sg_updateShopState({ status, shopId: item.shopId }).then(() => {
          this.getList({
            ...this.form,
            pageCurrent: this.currentPage,
            pageSize: 15,
          });
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消',
        });
      });
    },
    editBuiness(item) {
      this.$router.push({
        name: 'EditBusiness',
        query: { edit: 1, shopId: item.shopId },
      });
    },
    gotoAd(id) {
      this.$router.push({
        name: 'AdvertisingList',
        query: { shopId: id },
      });
    },
    getList(param) {
      $agent_shopPage(param).then((res) => {
        this.tableData = res.records;
        this.tableData.forEach((item) => {
          this.addressList.forEach((op) => {
            if (op.area_id === item.province) {
              item.province = op.area_name;
            }
            if (op.area_id === item.city) {
              item.city = op.area_name;
            }
            if (op.area_id === item.district) {
              item.district = op.area_name;
            }
          });
          item.areaName = (item.province || '') + (item.city || '') + (item.district || '');
        });
        this.totalPage = res.pages;
      });
    },
    // 遍历树状区域图
    areaTree(area) {
      area.forEach((item) => {
        this.addressList.push(item);
        if (item.children && item.children.length > 0) {
          this.areaTree(item.children);
        }
      });
    },
    changMoney() {

    },

    pageChange() {
      if (this.area) {
        this.form.province = this.area[1];
        this.form.city = this.area[2];
        this.form.district = this.area[3];
      }
      this.getList({
        pageCurrent: this.currentPage,
        pageSize: 15,
        ...this.form,
      });
    },
    screen() {
      this.currentPage = 1;
      if (this.area) {
        this.form.province = this.area[1];
        this.form.city = this.area[2];
        this.form.district = this.area[3];
      }
      this.getList({ ...this.form, pageSize: 15 });
    },
    clear() {
      this.form = {
        belong: '',
        billboardState: '',
        shopName: '',
        spreadId: '',
        supportBusiness: '',
        telState: '',
      };
      this.area = [];
      this.getList({
        pageCurrent: 1,
        pageSize: 15,
      });
    },
    inputPhone(value) {
      if (value.length > 11) {
        this.form.phone = value.slice(0, 11);
      }
    },
    inputShopId(value) {
      if (value.length > 11) {
        this.form.num = value.slice(0, 11);
      }
    },
    goAuthorize(data) {
      if (data.authStatus === 1) {
        this.$confirm('您已授权绑定过美团平台，切换绑定需要先解除绑定账号，是否需要解绑美团平台账号？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          $sg_doShopUnBind({ shopId: data.shopId }).then((res) => {
            this.openNewWindow(res);
          });
        }).catch((err) => {
          console.log(err);
        });
      } else {
        $sg_doAppAuthToken({ shopId: data.shopId, isOrder: 2 }).then((res) => {
          this.openNewWindow(res);
        });
      }
    },
    openNewWindow(res) {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      const newWindow = window.open(res, '_blank', 'width=500px,height=550px,top=200px,left=500px,scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes');
      this.timer = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(this.timer);
          this.timer = null;
          this.getList({
            pageCurrent: this.currentPage,
            pageSize: 15,
            ...this.form,
          });
        }
      }, 2000);
    },
    saveBillboard() {
      const params = this.codeform;
      if (this.codeform.status) {
        if (!this.codeform.secondQrUrl) {
          this.$message.error('请上传图片');
          return;
        }
        if (!this.codeform.linkBillboardContent) {
          this.$message.error('请输入链接广告');
          return;
        }
        // if (!this.codeform.linkUrl) {
        //   this.$message.error('请输入链接url');
        //   return;
        // }
      }
      params.status = +this.codeform.status;
      $sg_saveBillboard(params).then((res) => {
        this.centerDialogVisible = false;
        this.getList({
          pageCurrent: this.currentPage,
          pageSize: 15,
        });
        console.log(res, 'ee');
      });
    },
    // 打开二维码修改配置
    // openCodeDialog(id, status) {
    //   console.log(status);
    //   if (status) {
    //     $sg_getBillboard({ shopId: id }).then((res) => {
    //       this.isread = true;
    //       this.codeform = res;
    //       this.codeform.status = !!res.status;
    //       this.centerDialogVisible = true;
    //     });
    //   } else {
    //     this.codeform = {
    //       id: null,
    //       shopId: id,
    //       status: false,
    //       secondQrUrl: '',
    //       linkBillboardContent: '加粉丝福利群，更多福利，更多优惠',

    //     };
    //     this.isread = false;
    //     this.centerDialogVisible = true;
    //   }
    // },
    // 图片上传
    async img_head_upload(file) {
      const uploadImg = new UploadImg({ file: file.file, type: 'logo' });
      const resp = await uploadImg.upload();
      this.codeform.secondQrUrl = resp;
    },
  },
};
</script>

<style lang="scss">
.shop-list-image-slot {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
}
</style>
<style scoped lang="scss">
.business-reviewed-page {
  width: 100%;
  min-width: 1400px;
  // height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.dialong-style {
  /deep/ .el-dialog__body {
    padding: 25px 50px 30px !important;
  }
  .remark-line {
    display: inline-block;
    height: 20px;
    width: 4px;
    border-radius: 4px;
    background: red;
  }
  .remark-title {
    display: inline-block;
    vertical-align: 3px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
  }
  .unploadButton {
    width: 150px;
    height: 32px;
    border: 1px solid #ef3f46;
    border-radius: 4px;
    height: 32px;
    background: #ef3f46;
    border-radius: 4px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    margin-top: 10px;
  }
}
.active {
  color: #f22424;
}
.el-form--inline .el-form-item {
  margin-right: 50px;
}
.inputStyle {
  width: 180px;
}
input:focus-visible {
  outline: none;
}
.vue-input-style {
  width: 50px;
  height: 30px;
  border: 1px solid #6666;
  border-radius: 5px;
}

.timeChoose {
  width: 110px;
  text-align: left;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.sure {
  margin-left: 30px;
}
.orderExplain {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.orderExplainTxt {
  font-size: 14px;
  font-weight: 400;
  color: #f22424;
}
.el-table {
  // min-height: 580px;
  // & > th {
  //   background-color: #ef3f46;
  //   color: #fff;
  // }
  .goAuthorize{
    cursor: pointer;
    color: #f22424;
  }
}
.question {
  width: 14px;
  height: 14px;
  margin-left: 8px;
  cursor: pointer;
}
.status1 {
  color: #333;
}
.status2 {
  color: #f22424;
}
</style>
