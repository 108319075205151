import api from './api';

/**
 * 发单配置
 */

export const $sp_saveSendConfig = (params) => api.post('/shop/v1/Config/saveSendConfig', params);
// 第三方对接
export const $sg_getThirdSendConfig = (params) => api.get('/shop/v1/Config/getSendConfig', params);

/**
 * 无线打印机
 */

// 平台获取商品分类
export const $sg_getGoodsSort = (params) => api.get('/mall/v1/GoodsManage/getGoodsSort', params);
export const $sg_printerList = (params) => api.get('/shop/v2/wirelessPrinter/printerList', params);
export const $sg_delPrinter = (params) => api.post('/shop/v2/wirelessPrinter/delPrinter', params);
export const $sg_addPrinter = (params) => api.post('/shop/v2/WirelessPrinter/addPrinter', params);
// 保存店长的打印机配置
export const $sg_savePrinter = (params) => api.get('/j/mch_printer_config/update', params);
export const $sg_queryPrinter = (params) => api.get('/j/mch_printer_config/query', params);
export const $sg_saveConfig = (params) => api.post('/shop/v2/wirelessPrinter/saveConfig', params);
export const $sg_getPrinter = (params) => api.get('/shop/v1/Printer/getPrinter', params);
export const $sg_getYiLianYunAuthorUrl = (params) => api.get('/shop/v2/wirelessPrinter/getYiLianYunAuthorUrl', params);

/**
 * 铃声设置
 */

export const $sg_shop_setShopConfig = (params) => api.post('/mall/v3/shop/setShopConfig', params);
// 获取外送配置
export const $sg_getMallShopConfigs = (params) => api.get('/mall/v2/MallShop/getMallShopConfig', params);


export const $sg_addMch_printer = (params) => api.post('/j/mch_printer/add', params);

export const $sg_updateMch_printer = (params) => api.post('/j/mch_printer/update', params);
export const $sg_delete_printer = (params) => api.get('/j/mch_printer/delete', params);

// export const $sg_query_printer = (params) => api.postNoMessage('http://o2o-java.xd0.co:9223/j/mch_printer/query', params);

export const $sg_query_printer = (params) => api.postNoMessage('/j/mch_printer/query', params);


export const $sg_toWechatAuthorizePage = (params) => api.postNoMessage('/j/shop/auth/wechat/toWechatAuthorizePage', params);
export const $sg_wechat_getShopDetail = (params) => api.postNoMessage('/j/shop/auth/wechat/getShopDetail', params);


export const $sg_getDeductionConfig = (params) => api.get('/mch/v1/ScoreCard/getCardScoreRule', params);
export const $sg_saveDeductionConfig = (params) => api.post('/mch/v1/ScoreCard/saveCardScoreRule', params);
export const $sg_queryByShopId = (params) => api.get('/j/mch_mini_app/queryByShopId', params);
export const $sg_saveOrUpdate = (params) => api.post('/j/mch_mini_app/saveOrUpdate', params);
export const $sg_shopUnBind = (params) => api.post('/j/shop/auth/wechat/shopUnBind', params);
export const $sg_DistributionFeeDimGet = () => api.get('/j/distribution/agent/tDistributionFeeDim/get');
export const $sp_tDistributionFeeDimAdd = (params) => api.postNoTips('/j/distribution/agent/tDistributionFeeDim/add', params);
export const $sg_tDistributionFeeTplDimTemp = (id) => api.get(`/j/distribution/agent/tDistributionFeeTplDim/getTplByType/${id}`);
// 判断是否设置
export const $sg_selectMeituanSetting = () => api.get('/api/agent/biz/agent/selectMeituanSetting');
// 设置骑手配置
export const $sp_addSetting = (params) => api.get('/api/agent/biz/agent/addSetting', params);
// 判断是否配置完善
export const $sg_isBillingRules = () => api.getNoTips('/j/distribution/agent/tDistributionFeeDim/isBillingRules');
// 配送设置新增
export const $sp_psConfigAdd = (params) => api.postNoTips('/j/distribution/agent/distribution/config/add', params);
// 配送设置查询
export const $sg_psConfigGet = (params) => api.get('/j/distribution/agent/distribution/config/get', params);
// 配送设置修改
export const $sp_psConfigUpdate = (params) => api.postNoTips('/j/distribution/agent/distribution/config/update', params);
// 查询代理商是否有接力单正在进行
export const $sg_agentRelayOrder = (params) => api.get('/j/distribution/agent/distributionRunErrands/isAgentRelayOrder', params);
// 获取代理商信息
export const $sg_getAgentInfo = (params) => api.get(`/api/agent/biz/agent/detail/${params}`);

// 美团配送-规则列表
export const $sg_ruleList = (params) => api.get('/w/agent/ruleList', params);

// 美团配送-添加or修改规则
export const $sp_doRule = (params) => api.post('/w/agent/doRule', params);

// 美团配送-删除规则
export const $sp_delRule = (params) => api.post('/w/agent/delRule', params);

// 美团配送-保存规则关联的店铺
export const $sp_doRuleShop = (params) => api.post('/w/agent/doRuleShop', params);

// 美团配送-规则下的店铺列表
export const $sg_getRuleShopList = (params) => api.get('/w/agent/getRuleShopList', params);

