<template>
    <div class="block">
      <el-cascader
        expand-trigger="hover"
        :props="areaProps"
        :options="areaOptions"
        @change="selectedChange"
        v-model="currentValue"
        :disabled="!addressFlag"
        style="width: 100%"
      ></el-cascader>
    </div>
</template>

<script>
import { getFormatArea } from '@/global/globalMethods';

export default {
  name: 'ShopPcAreaSelect',
  data() {
    return {
      areaProps: { // 字段名字
        value: 'area_id',
        label: 'area_name',
      },
      areaOptions: [], // 区域列表
      currentValue: [], // 选中的选项
    };
  },

  props: {
    value: Array,
    addressFlag: Boolean,
  },

  watch: {
    value: {
      handler(val) {
        this.currentValue = [...val];
      },
      immediate: true,
    },
  },

  created() {
    getFormatArea().then((data) => {
      this.areaOptions = data;
    });
  },

  methods: {
    selectedChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>
