<template>
  <div class="shop-admin-info-page">
    <el-card class="box-card">
      <div slot="header" class="header">店铺信息</div>
      <div>
        <el-form label-width="98px">
          <el-row :gutter="20">
            <el-col :span="10" :offset="0">
              <el-form-item label="店铺名称">
                <el-input v-model="shopInfo.shopName" :disabled="!nameFlag" placeholder="请输入店铺名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <!-- <el-form-item label="抽佣比例">
                <el-input v-model="shopInfo.amount" :disabled="!nameFlag" placeholder="请输入抽佣比例"></el-input>%
              </el-form-item> -->
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10" :offset="0">
              <!-- <el-form-item label="经营类目">
                <el-input v-model="shopInfo.service" :disabled="!nameFlag"  placeholder="请输入经营类目"></el-input>
              </el-form-item> -->
              <el-form-item label="主营类目">
                <el-select v-model="typeValue" placeholder="美食/快餐" :disabled="!typeFlag" @change="shopTypechange">
                  <el-option v-for="item in shopType" :key="item.type_id" :label="item.type_name" :value="item.type_id">
                  </el-option>
                </el-select>
                <el-select class="ml5" v-model="childValue" :disabled="!typeFlag" placeholder="小吃快餐" @change="shopTypeChildChange">
                  <el-option v-for="item in shopTypeChild" :key="item.child_id" :label="item.child_name" :value="item.child_name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="客服电话">
                <el-input v-model="shopInfo.serviceTel" :disabled="!nameFlag" placeholder="请输入客服电话"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10" :offset="0">
              <el-form-item label="店铺账号">
                <el-input v-model="shopInfo.tel" :disabled="!nameFlag" placeholder="请输入店铺账号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :offset="0">
              <el-form-item label="账号密码">
                <el-input v-model="shopInfo.password" :disabled="!nameFlag" placeholder="请输入账号密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="标签设置">
            <span style="color: #aaaaaa; font-size: 12px">此标签将展示给用户，请谨慎选择，最多可选择三个标签</span>
          </el-form-item>
          <div class="remark-tags">
            <div style="padding-top: 30px">
              <p style="color: #aaaaaa; font-size: 12px">推荐标签</p>
              <p style="color: #aaaaaa; font-size: 12px;padding-top: 20px">常用标签</p>
            </div>
            <aside style="display: inline-block; width: 920px; vertical-align: top">
              <ul>
                <li v-for="(item, index) in tagList" :key="index" :class="{ active: activeList.includes(item) }"
                  @click="checkTag(item, index)">
                  {{ item }}
                </li>
              </ul>
            </aside>
          </div>
          <el-form-item label="店铺区域">
            <area-select :addressFlag="addressFlag" v-model="area" class="iblock"></area-select>
          </el-form-item>
          <el-form-item label="店铺地址">
            <el-input class="mr5" :disabled="!addressFlag" placeholder="详细地址" v-model="shopInfo.address" style="width: 630px"></el-input>
            <el-button :disabled="!addressFlag" type="primary" @click="searchAddress">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <div v-show="showMap" id="shopPointContainer" style="height: 450px; width: 800px"></div>
          </el-form-item>
          <el-form-item label="营业时间">
            <!-- <el-time-picker is-range v-model="time" range-separator="到" start-placeholder="下单开始时间" end-placeholder="下单结束时间"
              placeholder="选择时间范围" format='HH:mm' value-format="yyyy-MM-dd HH:mm:ss">
            </el-time-picker> -->
             <el-cascader
              v-model="shopInfo.firstStart"
              clearable
              placeholder="选择开始时间"
              :options="optionsStartTime"
              separator=":"
            ></el-cascader>
            <span class="to">至</span>
            <el-cascader
              v-model="shopInfo.firstEnd"
              clearable
              :options="optionsEndTime"
              placeholder="选择结束时间"
              separator=":"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="相关照片">
            <ul class="auto-withdraw-photo-cards">
              <li style="width: 173px" v-for="(item, index) in imgUploadList" :key="index">
                <template v-if="imgStatus">
                  <div class="money-img-code" @click="changeHeadImg(item.url)">
                    <img :src="shopInfo[item.url] ? shopInfo[item.url] : unploadImg" 
                    class="code-img-money" style="width: 173px; height: 113px" />
                    <input class="hide" type="file" accept="image/*" @change="changeImg" style="display: none" />
                  </div>
                  <p>{{ item.title }}</p>
                </template>
              </li>
            </ul>
          </el-form-item>

          <el-form-item label="公众号appid">
            <el-input v-model="shopInfo.appId" :disabled="!nameFlag" placeholder="appid"></el-input>
          </el-form-item>
          <el-form-item label="公众号seceert">
            <el-input v-model="shopInfo.secret" :disabled="!nameFlag" placeholder="seceert"></el-input>
          </el-form-item>
          <el-form-item>
            <PrevReclick>
              <el-button type="primary" @click="saveShopInfo">提交保存
              </el-button>
            </PrevReclick>
            <el-button @click="goBack">取消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { UploadImg } from '@/base/global/common';
// import { getAreaNames } from '@base/utils/utils';
import { getFormatArea } from '@/global/globalMethods';
import { AMapUtils } from '@base/utils';
import AreaSelect from '@/components/common/AreaSelect.vue';
import defaultImg from '@/assets/img/unploadHeadImg.png';
import {
  $sg_getShopExt,
  $sg_getShopCate,
  // $sgw_getShopAuthorityV2,
} from '@/api/shop';
import { $agent_addOrUpdateMerchant, $sg_merchantInfoByApp } from '@/api/agentAll';

import unploadImg from '@/assets/img/common/unploadImg.png';
import ChooseFile from '@base/utils/file/ChooseFile';
import FileUploader from '@base/utils/file/FileUploader';

const tagList = [
  'WIFI',
  '回头客多',
  '商品美味',
  '分量足',
  '服务态度好',
  '环境好',
  '价格便宜',
  '可打折',
  '有优惠',
  '适合约会',
  '老板风趣',
  '24小时营业',
  '味道偏辣',
  '环境大',
  '味道偏甜',
  '餐具好看',
  '茶具',
  '休息室',
  '免费停车',
];
const imgUploadList = [
  {
    url: 'idCardFront',
    title: '身份证正面照',
  },
  {
    url: 'idCardBack',
    title: '身份证反面照',
  },
  {
    url: 'doorImg',
    title: '店铺门头照',
  },
  {
    url: 'shopInPic',
    title: '店铺内景照',
  },
  {
    url: 'businessPath',
    title: '营业执照照片',
  },
  {
    url: 'logoPath',
    title: '店铺头像',
  },

];
export default {
  name: 'shopInfo',
  components: {
    AreaSelect,
  },
  data() {
    return {
      unploadImg,
      imgUploadList,
      activeList: [],
      tagList,
      shopInfo: {
        address: '',
        logo_path: defaultImg,
        shopName: '',
        tel: '',
        password: '',
        firstStart: '',
        firstEnd: '',
      },
      area: [],
      shopPointVisible: false,
      mapSearchInput: '',
      map: {},
      temporary: {}, // 暂存坐标
      shopType: [],
      shopTypeChild: [],
      typeValue: '',
      childValue: '',
      notice: '',
      headFlag: true,
      nameFlag: true,
      typeFlag: true,
      addressFlag: true,
      showMap: false,
      form: {},
      imgStatus: true,
      aMapUtils: null,
      // time: null,
      startTime: null,
      endTime: null,
      optionsStartTime: [],
      optionsEndTime: [],
      areaList: [],
      // lon: null,
      // lat: null,
    };
  },
  created() {
    this.getShopCate();
  },
  async mounted() {
    await getFormatArea();
    const state = this.$route.query.edit;
    if (state) {
      this.getShopCate().then(() => {
        this.shopDetail({ shopId: this.$route.query.shopId });
      });
    }
    const self = this;
    
    this.$nextTick(() => {
      self.initMap();
      this.datePickerData();
    });
    const area = JSON.parse(sessionStorage.getItem('formatArea'));
    this.areaTree(area[0].children, '230000');
  },
  methods: {
    shopDetail(param) {
      $sg_merchantInfoByApp(param).then((res) => {
        this.shopInfo = res;
        this.area = ['100001', res.province, res.city, res.district];
        if (this.shopInfo.shopKeywords) {
          this.activeList = this.shopInfo.shopKeywords.split(',');
        }

        if (res.firstStart) {
          this.shopInfo.firstStart = res.firstStart !== null
            ? [res.firstStart - (res.firstStart % 3600), res.firstStart % 3600]
            : '';
        } else {
          this.shopInfo.firstStart = '';
        }
        if (res.firstEnd) {
          this.shopInfo.firstEnd = res.firstEnd !== null
            ? [res.firstEnd - (res.firstEnd % 3600), res.firstEnd % 3600]
            : '';
        } else {
          this.shopInfo.firstEnd = '';
        }
        
        if (this.shopInfo.service) {
          const service = this.shopInfo.service.split(',') || [];
          this.typeValue = +service[0] || null;
          this.childValue = +service[1] || null;
          this.shopTypechange();
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    // 选择标签
    checkTag(item) {
      if (this.activeList.includes(item)) {
        this.activeList.splice(this.activeList.indexOf(item), 1);
        return;
      }
      if (this.activeList.length < 3) {
        this.activeList.push(item);
      } else {
        this.activeList.shift();
        this.$nextTick(() => {
          this.activeList.push(item);
        });
      }
    },
    changeImg(event) {
      const config = {
        file: event.target.files[0],
        width: 750,
      };
      event.target.value = null // eslint-disable-line
      this.$refs.imgCut.open(config);
    },
    changeHeadImg(url) {
      const change = new ChooseFile();
      change.chooseImage(this.getFile.bind(this, url));
    },
    getFile(urls, file) {
      console.log(urls, file, '124');
      const fileUploder = new FileUploader(file, {
        width: 173,
        addLibrary: false,
      });
      fileUploder
        .upload()
        .then((url) => {
          this.imgStatus = false;
          this.$nextTick(() => {
            this.imgStatus = true;
            this.shopInfo[urls] = this.$imgPrefix(url, '/img/user/def_User_1.png');
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveShopInfo() {
      // eslint-disable-next-line
      const regular = /^.{1,20}$/
      const telRegular = /^\d{11}$/;
      const numRegular = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/;
      const pswRegular = /^\w{6,20}$/;
    
      if (!regular.test(this.shopInfo.shopName)) {
        this.$message.error('请输入店铺名称20个字符以内');
        return;
      }
      if (!telRegular.test(this.shopInfo.tel)) {
        this.$message.error('请输入正确的店铺账号');
        return;
      }
      if (this.area.length === 0) {
        this.$message.error('所在区域不能为空');
        return;
      }
      if (!this.shopInfo.address) {
        this.$message.error('详细地址不能为空');
        return;
      }
      console.log(this.shopInfo.mapLon, this.shopInfo.mapLat, 'map');
      if (!this.shopInfo.mapLon || !this.shopInfo.mapLat) {
        this.$message.error('地理坐标不能为空');
        return;
      }
      if (this.shopInfo.amount) {
        if (!numRegular.test(this.shopInfo.amount)) {
          this.$message.error('请输入正确的抽佣比例，数字且最多保留两位小数');
          return;
        }
      }
      if (this.shopInfo.serviceTel) {
        if (!telRegular.test(this.shopInfo.serviceTel)) {
          this.$message.error('请输入正确客服电话');
          return;
        }
      }
      if (this.shopInfo.password) {
        console.log(pswRegular.test(this.shopInfo.password), 'regular');
        if (!pswRegular.test(this.shopInfo.password)) {
          this.$message.error('请输入密码6-20个字符数字,字母,特殊符号');
          return;
        }
      }
      if (this.shopInfo.appId) {
        if (!regular.test(this.shopInfo.appId)) {
          this.$message.error('请输入appId1-20个字符');
          return;
        }
      }
      if (this.shopInfo.seceert) {
        if (!regular.test(this.shopInfo.seceert)) {
          this.$message.error('请输入appId1-20个字符');
          return;
        }
      }
      this.shopInfo.country = this.area[0];
      this.shopInfo.province = this.area[1];
      this.shopInfo.city = this.area[2];
      this.shopInfo.district = this.area[3];
     
     
      const startTime = this.shopInfo.firstStart;
      const endTime = this.shopInfo.firstEnd;
    
      let firstType;
      if (endTime[0] + endTime[1] > startTime[0] + startTime[1]) {
        firstType = 1;
      } else {
        firstType = 2;
      }
      this.shopInfo.firstType = firstType;
      this.shopInfo.firstStart = startTime[0] + startTime[1];
      this.shopInfo.firstEnd = endTime[0] + endTime[1];
      this.shopInfo.service = `${this.typeValue},${this.shopInfo.shop_type_child}`;
      // Reflect.deleteProperty(this.shopInfo, 'foo');
      if (this.activeList && this.activeList.length) {
        this.shopInfo.shopKeywords = this.activeList.join();
      }
    
      $agent_addOrUpdateMerchant(this.shopInfo).then((res) => {
        if (res.msg === '成功') {
          this.$router.push({ name: 'Reviewed' });
        }
      });
    },
   
    datePickerData() {
      const arr = [];
      for (let i = 0; i < 24; i += 1) {
        const json = {
          label: i < 10 ? `0${i}` : i,
          value: i * 3600,
          children: [],
        };
        for (let k = 0; k < 60; k += 1) {
          json.children.push({
            label: k < 10 ? `0${k}` : k,
            value: k * 60,
          });
        }
        arr.push(json);
      }
      this.optionsStartTime = arr;
      this.optionsEndTime = arr;
    },

    shopTypechange() {
      this.shopType.forEach((item) => {
        if (item.type_id === this.typeValue) {
          this.shopInfo.shop_type = item.type_id;
          this.shopTypeChild = item.child;
          this.childValue = item.child[0].child_name;
        }
      });
      this.shopTypeChildChange();
    },
    shopTypeChildChange() {
      this.shopTypeChild.forEach((item) => {
        if (item.child_name === this.childValue) {
          this.shopInfo.shop_type_child = item.child_id;
        }
      });
    },
    // 获取分类
    getShopCate() {
      let has_child = false;
      return $sg_getShopCate().then((data) => {
        data.forEach((item) => {
          if (item.type_id === this.shopInfo.shop_type) {
            this.typeValue = item.type_name;
            this.shopTypeChild = item.child;
            this.shopInfo.shop_type = item.type_id;
          }
          item.child.forEach((i) => {
            if (i.child_id === this.shopInfo.shop_type_child) {
              this.childValue = i.child_name;
              this.shopInfo.shop_type_child = i.child_id;
              has_child = true;
            }
          });
        });
        this.shopType = data;
        this.shopType.forEach((item) => {
          if (item.type_name === this.typeValue) {
            this.shopTypeChild = item.child;
            if (!has_child) {
              this.childValue = item.child[0].child_name;
            }
          }
        });
      });
    },
    // 获取公告
    getShopExt() {
      $sg_getShopExt().then((res) => {
        this.notice = res.notice;
      });
    },
    initMap() {
      this.aMapUtils = new AMapUtils('shopPointContainer');
      this.aMapUtils.cityMap().then(() => {
        this.showMap = true;
        this.aMapUtils.mapAddEvent((map_lon, map_lat) => {
          this.aMapUtils.getAddress({ map_lon, map_lat }).then((res) => {
            const areaNumRegular = /^\d+\.\d{6,}$/;
            const sixformat = /^\d+\.\d{6}/;
            if (areaNumRegular.test(map_lon)) {
              this.shopInfo.mapLon = ((`${map_lon}`).match(sixformat))[0];
            } else {
              this.shopInfo.mapLon = (`${map_lon}`);
            }
            if (areaNumRegular.test(map_lat)) {
              this.shopInfo.mapLat = (`${map_lat}`).match(sixformat)[0];
            } else {
              this.shopInfo.mapLat = (`${map_lat}`);
            }
            
            this.$nextTick(() => {
              this.shopInfo.address = res;
            });
          });
        });
      });
    },
    searchAddress() {
      let cityName = null;
      this.areaList.forEach((item) => {
        // eslint-disable-next-line eqeqeq
        if (item.area_id == this.area[2]) {
          cityName = item.area_name;
        }
      });
      console.log(this.shopInfo.address, cityName, 'ciyt');
      this.aMapUtils.search(this.shopInfo.address, cityName).then((res) => {
        this.shopInfo.mapLon = '';
        this.shopInfo.mapLat = '';
        console.log(res, '33344'); 
        if (res.pois.length) {
          const areaNumRegular = /^\d+\.\d{6,}$/;
          const sixformat = /^\d+\.\d{6}/;
          if (areaNumRegular.test(`${res.pois[0].location.lng}`)) {
            this.shopInfo.mapLon = (`${res.pois[0].location.lng}`).match(sixformat)[0];
          } else {
            this.shopInfo.mapLon = (`${res.pois[0].location.lng}`);
          }
          if (areaNumRegular.test(`${res.pois[0].location.lat}`)) {
            // eslint-disable-next-line no-param-reassign
            this.shopInfo.mapLat = (`${res.pois[0].location.lat}`).match(sixformat)[0];
          } else {
            this.shopInfo.mapLat = (`${res.pois[0].location.lat}`);
          }
        }
        this.addressList = res.pois;
        this.resetMarker();
      });
    },

    resetMarker() {
      if (this.markList && this.markList.length > 0) {
        this.aMapUtils.map.remove(this.markList);
      }
      this.markList = [];
      this.addressList.forEach((address) => {
        this.markList.push(this.aMapUtils.addMaker([address.location.lng, address.location.lat]));
      });
      this.aMapUtils.map.setFitView(this.markList);
    },
    // 遍历树状区域图
    areaTree(area) {
      area.forEach((item) => {
        this.areaList.push(item);
        if (item.children && item.children.length > 0) {
          this.areaTree(item.children);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.shop-admin-info-page {
  background-color: #fff;
  height: 100vh;
  overflow-y: auto;
  .el-form-item__label {
    width: 110px !important;
  }
  .auto-withdraw-photo-cards {
    padding: 20px 30px 30px 30px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    div {
      cursor: pointer;
    }
    p {
      font-size: 14px;
      line-height: 40px;
      color: #999999;
      text-align: center;
    }
  }
  .unploadButton {
    width: 170px;
    height: 32px;
    border: 1px solid #ef3f46;
    border-radius: 4px;
    width: 170px;
    height: 32px;
    background: #ef3f46;
    border-radius: 4px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    margin-left: 20px;
  }
  .shop-head-img {
    label {
      line-height: 200px;
    }
  }
  .remark-tags {
    margin-top: -20px;
    margin-bottom: 10px;
    div {
      display: inline-block;
      margin-left: 37px;
    }
    p {
      margin-bottom: 20px;
    }
    ul {
      display: inline-block;
      display: flex;

      flex-flow: wrap;
      justify-content: space-around;
      li {
        margin-top: 20px;
        margin-left: 20px;
        line-height: 32px;
        /* padding-bottom: 13px; */
        margin-bottom: 5px;
        color: #999999;
        cursor: pointer;
        padding: 0 10px;
        border-radius: 4px;
        border: 1px solid #e4e4e4;
      }
      li:nth-child(n + 11) {
        margin-left: 0px !important;
      }
    }
  }

  .avatar-uploader {
    width: 200px;
    height: 200px;
    line-height: 80px;
    border: 1px dashed #e5e5e5;
    border-radius: 5px;
    text-align: center;
    i {
      font-size: 20px;
    }
  }
  .el-input {
    width: 260px;
  }
  .active {
    color: #ef3f46 !important;
    border: 1px solid #ef3f46 !important;
    opacity: 1;
    border-radius: 4px;
    background: rgba(231, 38, 28, 0.06);
  }
}
</style>
