import api from './api';
// 代理商列表
export const $agent_selectAgent = (param) => api.get('/api/agent/biz/agent/selectAgent', param, {
  headers: {
    tags: 'dev',
  },
});
// 查看上级  /api/agent/biz/agent/selectPreAgentPlat
export const $agent_selectPreAgentPlat = (param) => api.get('/api/agent/biz/agent/selectPreAgentPlat', param, {
  headers: {
    tags: 'dev',
  },
});
// /api/agent/biz/merchant/selectTShopInfo
export const $agent_selectTShopInfo = () => api.get('/api/agent/biz/merchant/selectTShopInfo');
// 新增代理商 /api/agent/biz/agent/insertAgent
export const $agent_insertAgent = (param) => api.post('/api/agent/biz/agent/insertAgent', param, {
  headers: {
    tags: 'dev',
  },
});
// 编辑代理商 /api/agent/biz/agent/updateByAgentSid
export const $agent_updateByAgentSid = (param) => api.post('/api/agent/biz/agent/updateByAgentSid', param, {
  headers: {
    tags: 'dev',
  },
});
// 商户列表 /api/agent/biz/merchant/shopPage
export const $agent_shopPage = (param) => api.postNoMessage('/api/agent/biz/merchant/shopPage', param, {
  headers: {
    tags: 'dev',
  },
});
// 新增商户 /api/agent/biz/merchant/addOrUpdateMerchant
export const $agent_addOrUpdateMerchant = (param) => api.postNoReject('/api/agent/biz/merchant/addOrUpdateMerchant', param, {
  headers: {
    tags: 'dev',
  },
});
// 归属分公司查询 /api/agent/biz/merchant/spreadInfoList
export const $sp_spreadInfoList = (param) => api.get('/api/agent/biz/merchant/spreadInfoList', param, {
  headers: {
    tags: 'dev',
  },
});
// 引流二维码 /api/agent/biz/merchant/editShopBillboardByShopId
export const $sg_saveBillboard = (param) => api.post('/api/agent/biz/merchant/editShopBillboardByShopId', param, {
  headers: {
    tags: 'dev',
  },
});
// 修改配送补贴 /api/agent/biz/merchant/editSystemSubsidy
export const $sg_editSystemSubsidy = (param) => api.post('/api/agent/biz/merchant/editSystemSubsidy', param, {
  headers: {
    tags: 'dev',
  },
});
// /api/agent/biz/merchant/updateNextBusinessExpireTime 修改商户营业状态
export const $sg_updateShopState = (param) => api.post('/api/agent/biz/merchant/updateNextBusinessExpireTime', param, {
  headers: {
    tags: 'dev',
  },
});
// 商户编辑 /api/agent/biz/merchant/merchantInfoByApp
export const $sg_merchantInfoByApp = (param) => api.get('/api/agent/biz/merchant/merchantInfoByApp', param, {
  headers: {
    tags: 'dev',
  },
});
export const $sg_getShopCate = () => api.get('/mall/v1/Shop/getShopCate');
// 切换代理商账户提现状态
export const $sp_changeAgentFundState = (param) => api.post('/api/agent/biz/checkOut/updateFundState', param, {
  headers: {
    tags: 'dev',
  },
});
