import { Message } from 'element-ui';
import { $p_uploadImageBase64, $sp_createPlatPicture } from '@/api/common';
import { verifyTelByMessage, copyStringCom } from '@base/utils/utils';
import UploadImgCom from '@base/utils/file/upload';

export const clearpop = Message;


export class UploadImg extends UploadImgCom {
  constructor(option) {
    const secondOption = {
      uploadApiFn: $p_uploadImageBase64,
      createPlatPicture: $sp_createPlatPicture,
    };
    super(option, secondOption);
  }
}


export const verifyTel = (tel) => verifyTelByMessage(tel, Message.warning);


export const copyString = (string) => copyStringCom(string, Message.success, Message.error);
